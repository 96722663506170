import CoreManager from '../../clients/abdaCoreManager'

const resourceName = 'addresssuggestion'

const GetByStateId = stateId => CoreManager.get(`${resourceName}/state/${stateId}`)
const GetByZipCode = zipCode => CoreManager.get(`${resourceName}/${zipCode}`)

const AddressSuggestionRepository = {
  GetByStateId,
  GetByZipCode
}

export default AddressSuggestionRepository