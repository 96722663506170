import GuidUtils from '@/shared/utils/guid'

export default class StudentRegistration {
  constructor({
    id,
    localTrainingId,
    localTrainingName,
    modalityId,
    modalityName,
    categoryId,
    categoryName,
    classId,
    className,
    dateStart
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.localTrainingId = localTrainingId,
    this.localTrainingName = localTrainingName,
    this.modalityId = modalityId,
    this.modalityName = modalityName,
    this.categoryId = categoryId,
    this.categoryName = categoryName,
    this.classId = classId,
    this.className = className,
    this.dateStart = dateStart
  }
}
