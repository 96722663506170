import GuidUtils from '@/shared/utils/guid'

export default class Contact {
  constructor({
    id,
    contactTypeId,
    contactTypeName,
    name,
    phone,
    phone2,
    phone3,
    email,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.name = name,
    this.contactTypeId = contactTypeId,
    this.contactTypeName = contactTypeName
    this.phone = phone
    this.phone2 = phone2
    this.phone3 = phone3
    this.email = email
  }
}