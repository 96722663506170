import StudentRepository from '@/shared/http/repositories/socialProject/student'
import AddressSuggestionRepository from '@/shared/http/repositories/core/address-suggestion'
import genderEnum from '@/shared/enums/gender'
import deficiencyEnum from '@/shared/enums/yesNo'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { Money } from 'v-money'
import { dateFilter } from '@/shared/filters/ToDate'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import Roles from '@/shared/mixins/hasRoles'
import moment from 'moment'
import StudentDeficiencyRepository from '@/shared/http/repositories/socialProject/student-deficiency'

import { globalGetters as CidGetters } from '@/store/modules/cid/getters'
import { globalActions as CidActions } from '@/store/modules/cid/actionTypes'
import { globalGetters as SocialEntityGetters } from '@/store/modules/socialEntity/getters'
import { globalActions as SocialEntityActions } from '@/store/modules/socialEntity/actionTypes'
import { globalGetters as HealthPlanGetters } from '@/store/modules/healthPlan/getters'
import { globalActions as HealthPlanActions } from '@/store/modules/healthPlan/actionTypes'
import { globalGetters as DeficiencyTypeGetters } from '@/store/modules/deficiencyType/getters'
import { globalActions as DeficiencyTypeActions } from '@/store/modules/deficiencyType/actionTypes'
import { globalGetters as SchoolTypeGetters } from '@/store/modules/school/getters'
import { globalActions as SchoolTypeActions } from '@/store/modules/school/actionTypes'
import { globalGetters as CityGetters } from '@/store/modules/city/getters'
import { globalActions as CityActions } from '@/store/modules/city/actionTypes'
import { globalGetters as StateGetters } from '@/store/modules/state/getters'
import { globalActions as StateActions } from '@/store/modules/state/actionTypes'
import { globalGetters as StudentGetters } from '@/store/modules/student/getters'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'GeneralInfo',

  mixins: [Roles],

  component: {
    Money
  },

  props: {
    isNew: {
      type: Boolean
    },
    student: {
      type: Object
    },
    studentInactiveId: {
      type: String,
      default: null
    }
  },

  inject: [ 'parentValidator' ],

  data: () => ({
    genders: Object.values(genderEnum),
    hasDeficiencyOptions: Object.values(deficiencyEnum),
    dateFormatted: null,
    showModalActiveStudent: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 2,
      masked: false
    },
    files: null,
    photo: 'resources/images/avatars/default-avatar.jpg',
    hasPhoto: false,
    showCid: null,
    cidDescription: null,
    allCidCode: null,
    incompleteValidate: false,
    disabledAllInputCid: false,
    showButtonRemoveDeficiency: false,
    showModalRemoveDeficiency: false
  }),

  created() {
    this.$validator.reset()
    this.fetchSocialEntities()
    this.fetchHealthPlans()
    this.fetchSchools()
    this.fetchStates()
    this.$validator = this.parentValidator
    this.checkPhotoExists()
  },

  computed: {
    hasProfilePhoto() {
      if (this.hasPhoto) {
        this.photo = `https://abda-files.s3-sa-east-1.amazonaws.com/${process.env.VUE_APP_S3BUCKET_PROFILE_PHOTO_ENVIRONMENT}/Files/Student/${this.studentGetter.id.toUpperCase()}/profile.jpg?${this.rng()}`
        return this.photo
      }

      return this.photo
    },
    ...mapGetters({
      cids: CidGetters.cids,
      cid: CidGetters.cid,
      cidCategories: DeficiencyTypeGetters.deficiencyTypes,
      socialEntities: SocialEntityGetters.socialEntities,
      healthPlans: HealthPlanGetters.healthPlans,
      schools: SchoolTypeGetters.schools,
      cities: CityGetters.cities,
      states: StateGetters.states,
      studentGetter: StudentGetters.student
    })
  },

  methods: {
    ...mapActions({
      fetchByCidCode: CidActions.FETCH_BY_CID_CODE,
      fetchAllCidCode: CidActions.FETCH_ALL_CID_CODE,
      fetchDeficiencyTypes: DeficiencyTypeActions.FETCH_DEFICIENCY_TYPES,
      fetchSocialEntities: SocialEntityActions.FETCH_SOCIAL_ENTITIES,
      fetchHealthPlans: HealthPlanActions.FETCH_HEALTH_PLANS,
      fetchSchools: SchoolTypeActions.FETCH_SCHOOLS,
      fetchCities: CityActions.FETCH_CITIES,
      fetchStates: StateActions.FETCH_STATES
    }),

    showRegisterDate() {
      const dateRegistration = dateFormattedFilter(this.student.registrationDate)
      if (dateRegistration == '-' && !this.isNew) {
        const currentDate = moment().format("DD/MM/YYYY")

        return currentDate
      }
      return dateRegistration
    },

    checkHasDeficiency() {
      if (!this.student.hasDeficiency) {
        this.showCid = false
        this.student.cidCode = null
        this.cidDescription = null
        this.student.cidCategory = null
        return
      }

      this.fetchDeficiencyTypes()
      this.showCid = true
    },

    checkValidatedInputsCid() {
      const invalidated = this.cidDescription == null || this.student.cidCategory == null

      if (invalidated) {
        return false
      }
      return true
    },

    getCidDescription (cidCode) {
      if (!cidCode) {
        this.cidDescription = null
        return
      }

      this.fetchByCidCode(cidCode)

      this.cidDescription = this.cid.description
    },

    getAllCidCode (cidCode) {
      if (!cidCode) {
        this.cidDescription = null
        return
      }

      if (cidCode.length < 2) return

      this.fetchAllCidCode(cidCode)
      this.allCidCode = this.cids.map(x => x.cidCode).sort()

      if (!this.allCidCode) {
        toast.info('CID NÃO ENCONTRADO', 'NÃO ACHEI O CID')
        return
      }
    },

    showModalActiveButton() {
      this.showModalActiveStudent = true
    },

    reactiveStudent () {
      if (!this.studentGetter.id) return

      loading.push()
      StudentRepository.Reactivate(this.studentGetter.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Aluno reativado com sucesso.', 'REATIVAÇÃO DE ALUNO')
          this.showModalActiveStudent = false

          if (this.studentInactiveId) {
            this.inactiveReasonStudentInactive()
          }

          window.location.reload()
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao reativar aluno.', 'REATIVAÇÃO DE ALUNO')
        })
    },

    inactiveReasonStudentInactive() {
      loading.push()
      StudentRepository.InactiveReasonStudentInactive(this.studentInactiveId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro inativar a razão da inativação do aluno', 'ERRO')
        })
    },

    getAddressSuggestion() {
      if (!this.student.zipCode || this.student.zipCode.length < 8) return

      loading.push()
      AddressSuggestionRepository.GetByZipCode(this.student.zipCode)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const result = res.data.data
          this.student.address = result.street
          this.student.district = result.neighborhood
          this.student.stateId = result.stateID

          this.onSelectState()
          this.student.cityId = result.cityID

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar por cep', 'ERRO')
        })
    },

    onSelectState() {
      const { cityId } = { ...this.student }

      if (!this.student.stateId) return

      this.fetchCities(this.student.stateId)

      const hasCity = this.cities.some(city => city.id === cityId)

      if (hasCity) {
        this.student.cityId = cityId
      }
    },

    removeDeficiency(id) {
      if (!id) return

      loading.push()
      StudentDeficiencyRepository.Delete(id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Deficiência inativada com sucesso!', 'INATIVAR DEFICIÊNCIA')

          this.cidDescription = null
          this.showModalRemoveDeficiency = false

          this.$emit('onGetById')

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao deletar a deficiência de estudante', 'ERRO')
        })
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    onDateContext(ctx) {
      this.dateFormatted = ctx.selectedFormatted
    },

    formatDate(event) {
      this.student.birthDate = dateFilter(event.currentTarget.value)
    },

    addFile () {
      this.$refs.photo.click()
    },

    handleFileUpload () {
      this.files = this.$refs.photo.files
      this.onUpload()
    },

    onUpload () {
      loading.push()
      let formData = new FormData()
      for (var i = 0; i < 1; i++) {
        let file = this.files[i]

        if(!this.isImageExtension(file)) return toast.error('Envie um arquivo com extensão de imagem.', 'ERRO')

        formData.append('files[' + i + ']', file)
      }
      StudentRepository.UploadPhoto(this.studentGetter.id, formData)
        .then(() => {
          loading.pop()
          setTimeout(() => {
            this.photo = this.photo.replace('?', `?${this.rng()}`)
          }, 1500)
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao enviar foto de perfil', 'ERRO')
        })
    },

    isImageExtension({ name }) {
      const extSplit = name.split('.')
      const ext = extSplit[extSplit.length -1]
      switch(ext.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'bmp':
        case 'png':
        case 'tiff':
        return true
      }
      return false
    },

    rng(min = 0, max = 10) {
      return Math.floor(Math.random() * (max - min) + min)
    },

    checkPhotoExists() {
      StudentRepository.S3FileExists(this.studentGetter.id)
        .then(res => {
          this.hasPhoto = res.data
          return Promise.resolve()
        })
        .catch(() => {
          toast.error('Foto de perfil não existe')
        })
    }
  },

  watch: {
    'student.stateId': function() {
      this.onSelectState()
    },

    student: {
      handler: function (value) {
        if (value.cidCategory) {
          this.checkHasDeficiency()
          this.getCidDescription(value.cidCode)
          this.disabledAllInputCid = true
        }
      },
      immediate: true
    }
  }
}
