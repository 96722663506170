import StudentAnamneseRepository from '@/shared/http/repositories/socialProject/student-anamnese'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import Roles from '@/shared/mixins/hasRoles'
import QuestionTypes from '@/shared/enums/questionType'

import { globalGetters as StudentGetters } from '@/store/modules/student/getters'
import { mapGetters } from 'vuex'

export default {
  name: 'Anamnese',

  props: {
    showSaveButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  mixins: [Roles],

  data: () => ({
    anamneses: {},
    answers: [],
    questionTypes: QuestionTypes
  }),

  computed: {
    ...mapGetters({
      student: StudentGetters.student
    })
  },

  methods: {
    reset() {
      this.answers = []
      this.$nextTick(() => {
        this.$validator.reset()
      })
    },

    getAnamneseByStudentId(studentId) {
      this.reset()
      loading.push()
      StudentAnamneseRepository.GetAnamneseQuestions(studentId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.anamneses = res.data.data
          this.anamneses.forEach(anamnese => {
            anamnese.questions.forEach(question => {
              if (question.answer === null && question.questionType === this.questionTypes.RATING.id) {
                question.rating = 0
                return
              }

              if (question.answer === null) {
                return
              }

              if(question.questionType === this.questionTypes.MULTIPLES_CHOICES.id) {
                question.selected = question.answer.split('|')
              }

              if(question.questionType === this.questionTypes.RATING.id) {
                question.rating = Number(question.answer)
              }
            })
          })

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar anamnese', 'ERRO')
        })
    },

    onSave(anamnese) {
      this.answers = []
      const questions = anamnese.questions

      questions.forEach(question => {
        if(question.questionType === this.questionTypes.MULTIPLES_CHOICES.id) {
          question.answer = question.selected.join('|')
        }
        if(question.questionType === this.questionTypes.RATING.id) {
          question.answer = question.rating.toString()
        }

        this.answers.push({
          AnamneseQuestionId: question.id,
          Answer: question.answer,
          AdditionalAnswer: question.additionalAnswer
        })
      })

      return this.onCreateOrUpdate()
    },

    onCreateOrUpdate() {
      loading.push()
      StudentAnamneseRepository.CreateAnswers(this.student.id, this.answers)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Anamnese salva com sucesso!', 'SALVAR ANAMNESE')
          this.getAnamneseByStudentId(this.student.id)
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar anamnese', 'ERRO')
        })
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    clickInSave() {
      const saveButton = document.querySelector('#saveButton')
      saveButton.click()
    }
  }
}
