import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'deficiency'
const resourceStudent = 'student'

const GetById = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}`)
// const GetAll = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}`)

const Create = data => SocialProjectManager.post(`${resourceStudent}/${data.studentId}/${resourceName}`, data)

const Update = data => SocialProjectManager.put(`${resourceStudent}/${data.studentId}/${resourceName}/${data.id}`, data)

const Delete = (id) => SocialProjectManager.delete(`${resourceStudent}/${resourceName}/${id}`)

const DeficiencyRepository = {
  GetById,
  // GetAll,
  Create,
  Update,
  Delete
}

export default DeficiencyRepository