import ContactTypeRepository from '@/shared/http/repositories/socialProject/contact-type'
import Contact from '@/shared/models/contact'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Roles from '@/shared/mixins/hasRoles'

export default {
  name: 'TableContact',

  mixins: [Roles],

  props: {
    contacts: {
      type: Array,
    }  
  },

  data: () => ({
    contact: new Contact(),
    contactTypes: [],
    emptyText: 'Não há contatos para mostrar',
    showModalDeleteContact: false,
    showModalContact: false,
    typeContactSelected: null,
    fields: [
      {
        key: 'contactTypeName',
        label: 'TIPO',
        class: 'left-header-border text-center'
      },
      {
        key: 'name',
        label: 'NOME',
        class: 'middle-header text-center'
      },
      {
        key: 'phone',
        label: 'TELEFONE - 1',
        class: 'middle-header text-center'
      },
      {
        key: 'phone2',
        label: 'TELEFONE - 2',
        class: 'middle-header text-center'
      },
      {
        key: 'phone3',
        label: 'TELEFONE - 3',
        class: 'middle-header text-center'
      },
      {
        key: 'email',
        label: 'E-MAIL',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  created() {
    this.getAllContactTypes()
  },

  methods: {
    clearForm() {
      this.showModalContact = false,
      this.contact = new Contact()
    },

    getAllContactTypes() {
      loading.push()
      ContactTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.contactTypes = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tipos de contato', 'ERRO')
        })
    },

    onSelectContactType() {
      if (this.contact.contactTypeId) {
        const contactType = this.contactTypes.filter(contactType => contactType.id === this.contact.contactTypeId)

        if (contactType.length > 0) {
          this.contact.contactTypeName = contactType[0].name
        }
      }
    },

    mergeItemList() {
      const isEditing = this.contactSelectedIndex != null

      if(isEditing) {
        this.contacts.splice(this.contactSelectedIndex, 1, this.contact)
        return
      }

      this.contacts.push(this.contact)
    },

    onSave() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.mergeItemList()
          this.clearForm()
        } else {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
        }
      })
    },

    onAddContact () {
      this.contactSelectedIndex = null
      this.showModalContact = true
    },

    onEditContact(contact) {
      this.contactSelectedIndex = contact.index
      this.contact = new Contact(contact.item)
      this.showModalContact = true
    },

    onShowModalDeleteContact(index) {
      this.contactSelectedIndex = index
      this.showModalDeleteContact = true
    },

    removeContact () {
      const index = this.contactSelectedIndex
      const hasElement = this.contacts[index] =! null

      if (!hasElement){
        toast.error('Não foi possível excluir o texto', 'ERRO')
        return
      }

      this.contacts.splice(index, 1)
      this.showModalDeleteContact = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
}
