import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import CategoryRepository from '@/shared/http/repositories/socialProject/category'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import StudentRegistration from '@/shared/models/studentRegistration'
import moment from 'moment'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import Roles from '@/shared/mixins/hasRoles'

export default {
  name: 'TableRegistration',

  mixins: [Roles],

  props: {
    registrations: {
      type: Array
    }
  },

  data: () => ({
    registration: new StudentRegistration(),
    categoryId: null,
    classId: null,
    emptyText: 'Não há matrículas para mostrar',
    showModalDeleteRegistration: false,
    showModalRegistration: false,
    modalityLocalTraining: [],
    listLocalTraining: [],
    modalities: [],
    categories: [],
    classes: [],
    fields: [
      {
        key: 'dateStart',
        label: 'DATA DA MATRÍCULA',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'localTrainingName',
        label: 'LOCAL',
        class: 'middle-header text-center'
      },
      {
        key: 'modalityName',
        label: 'MODALIDADE',
        class: 'middle-header text-center'
      },
      {
        key: 'categoryName',
        label: 'CATEGORIA',
        class: 'middle-header text-center'
      },
      {
        key: 'className',
        label: 'TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ]
  }),

  created() {
    this.loadModalityLocalTraining()
  },

  methods: {
    onSelectClasse () {
      if (!this.registration.classId) return

      const classe = this.classes.find(classe => classe.id === this.registration.classId)

      if (!classe) return

      this.registration.className = classe.name
    },

    async onSelectCategory() {
      if (!this.registration.categoryId) return
  
      const category = this.categories.find(category => category.id === this.registration.categoryId)
  
      if (!category) return
  
      this.registration.categoryName = category.name

      await this.getAllClasses()
    },

    async getAllCategories() {
      this.categories = []
      if (this.registration.modalityId) {
        await CategoryRepository.GetAllByModality(this.registration.modalityId)
          .then(response => {
            this.categories = response.data.data
          })
          .catch(() => {
            toast('Não foi possível carregar a lista de Categorias.')
          })
      }
    },

    loadModalityLocalTraining() {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
  
          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()
  
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    },
    
    onSelectLocalTraining() {
      let list = this.modalityLocalTraining
  
      if (this.registration.localTrainingId) {
        const type = this.listLocalTraining.filter(s => s.id === this.registration.localTrainingId)
  
        if (type.length > 0) {
          this.registration.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }
  
      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }
  
        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },
  
    async onSelectModality() {
      let list = this.modalityLocalTraining
  
      if (this.registration.modalityId) {
        let type = this.modalities.filter(s => s.id === this.registration.modalityId)
  
        if (type.length > 0) {
          this.registration.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }
  
      this.listLocalTraining = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }
  
        if (this.listLocalTraining.filter(l => l.id === local.id).length === 0) {
          this.listLocalTraining.push(local)
        }
      }

      await this.getAllCategories()
    },

    async getAllClasses() {
      if (!this.registration.categoryId) return
      this.classes = []

      loading.push()
      await ClassRepository.GetAllByCategory(this.registration.categoryId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const hasClass = this.registration.classId
          
          if(hasClass) {
            const hasClassId = this.classes.some(classe => classe.id === this.registration.classId)
            if(!hasClassId){
              this.registration.classId = null
              this.classes = []
            }
          }

          this.classes = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar turmas', 'ERRO')
        })
    },

    onAddRegistration () {
      this.clearForm()
      this.registrationSelectedIndex = null
      this.showModalRegistration = true
    },

    mergeItemList() {
      const isEditing = this.registrationSelectedIndex != null

      if(isEditing) {
        this.registrations.splice(this.registrationSelectedIndex, 1, this.registration)
        return
      }

      this.registration.dateStart = moment().format()
      this.registrations.push(this.registration)
    },

    format(value) {
      return dateFormattedFilter(value)
    },

    clearForm() {
      this.registration = new StudentRegistration()
    },

    onSave() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.mergeItemList()
          this.clearForm()
          this.showModalRegistration = false
        } else {
          toast.error('É necessário preencher os campos obrigatórios antes de continuar', 'ERRO')
        }
      })
    },

    async onEditRegistration(registration) {
      this.registrationSelectedIndex = registration.index
      this.registration = new StudentRegistration(registration.item)
      this.onSelectLocalTraining()
      await this.onSelectModality()
      await this.onSelectCategory()
      this.registration.classId = registration.item.classId
      this.showModalRegistration = true
    },

    onShowModalDeleteRegistration(index) {
      this.registrationSelectedIndex = index
      this.showModalDeleteRegistration = true
    },

    removeRegistration () {
      const index = this.registrationSelectedIndex
      const hasElement = this.registrations[index] =! null

      if (!hasElement){
        toast.error('Não foi possível excluir a matricula', 'ERRO')
        return
      }

      this.registrations.splice(index, 1)
      this.showModalDeleteRegistration = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    }
  }
}
