import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'anamnese'
const resourceStudent = 'student'

const GetAnamneseQuestions = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/anamneses`)
const GetAnamneseAnswers = studentId => SocialProjectManager.get(`${resourceStudent}/${studentId}/${resourceName}`)

const CreateAnswers = (studentId, data) => SocialProjectManager.post(`${resourceStudent}/${studentId}/${resourceName}`, data)

const StudentAnamneseRepository = {
  GetAnamneseQuestions,
  GetAnamneseAnswers,
  CreateAnswers
}

export default StudentAnamneseRepository
